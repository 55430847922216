<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>物流查询</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <deliver-view :deliverInfo="options.order.orderDeliverGoodsVo"></deliver-view>
</template>

<script>
import { useRoute } from "vue-router";
import orderApi from "@/api/orderApi";
import { reactive } from "vue";
import DeliverView from "@/components/order/deliverView.vue";
export default {
  setup() {
    const route = useRoute();
    const options = reactive({
      order: {
        id: route.params.Id,
        orderDeliverGoodsVo: null,
      },
      delivers: [],
    });
    const get = () => {
      orderApi.get({ orderId: route.params.Id }).then((res) => {
        if (res.status == 200) {
          options.order = res.result;
        }
      });
    };
    get();
    return {
      options,
    };
  },
  components: { DeliverView }
};
</script>

<style>
</style>